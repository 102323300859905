import Spinner from "@core/components/spinner/Fallback-spinner";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "redux/store";
import { setupAxiosInterceptor } from "services/axios";
// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import "./@core/scss/react/libs/react-select/_react-select.scss";
setupAxiosInterceptor(store);
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          <LazyApp />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
