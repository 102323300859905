import { IAuthSuccesResponse } from "interface/auth";
import axios from "./axios";

export const login = async (email: string, password: string, keepMeLoggedIn: boolean): Promise<IAuthSuccesResponse> => {
  const response = await axios.post<IAuthSuccesResponse>("/auth/login", { email, password, keepMeLoggedIn });
  return response.data;
};

export const forgotPassword = async (email: string): Promise<void> => {
  await axios.post("/auth/forgot-password", { email });
};

export const resetPassword = async (newPassword: string, passwordConfirmation: string, passwordResetToken: string): Promise<void> => {
  await axios.post("/auth/reset-password", { passwordResetToken, newPassword, passwordConfirmation });
};

export const refreshToken = async (): Promise<IAuthSuccesResponse> => {
  const response = await axios.post<IAuthSuccesResponse>("/auth/refresh");
  return response.data;
};

export const logout = async (): Promise<void> => {
  await axios.post("/auth/logout");
};
