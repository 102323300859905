import axios from "axios";
import { deleteAuth, updateAccessToken } from "redux/authSlice";
import { jsonToFormData } from "utility/helper";
import { refreshToken } from "./auth";

console.log("REACT_APP_BASE_URL", process.env.REACT_APP_API_BASE_URL);
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(async (config) => {
  return config;
});

export default instance;

export const setupAxiosInterceptor = (store: any) => {
  const { dispatch, getState } = store;

  instance.interceptors.request.use(async (config) => {
    if (!config.headers) {
      config.headers = {};
    }
    if (!config.url?.includes("/auth/")) {
      const { auth } = getState();
      if (auth.accessToken) {
        config.headers["Authorization"] = `Bearer ${auth.accessToken}`;
      }
    }
    if (config.data && typeof window !== "undefined") {
      const hasFile = Object.entries(config.data).find(([key, item]) => item instanceof File || key.includes("file") || item instanceof Blob);
      if (hasFile) {
        config.headers = {
          ...config.headers,
          ["Content-Type"]: "application/x-www-form-urlencoded",
        };
        config.data = jsonToFormData(config.data);
      }
    }
    return config;
  });
  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (!originalConfig.url.includes("/login") && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const response = await refreshToken();
            dispatch(updateAccessToken(response.accessToken));
            return instance(originalConfig);
          } catch (_error) {
            dispatch(deleteAuth());
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};
