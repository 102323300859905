import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthSuccesResponse, IUserSession } from "interface/auth";
import { logout } from "services/auth";
import { AppDispatch, AppState } from "./store";
import cookie from "cookie";

export interface AuthState {
  isAuthenticated: boolean;
  user?: IUserSession;
  accessToken?: string;
}

/** Check if user is logged in from server send cookie */
let isLoggedIn = false;
if (typeof document !== "undefined") {
  const cookies = cookie.parse(document.cookie);
  isLoggedIn = !!cookies.is_logged_in;
}
const initialState: AuthState = { isAuthenticated: isLoggedIn };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authSuccess: (state: AuthState, action: PayloadAction<IAuthSuccesResponse>) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
    },
    updateAccessToken: (state: AuthState, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    updateUserSession: (state: AuthState, action: PayloadAction<IUserSession>) => {
      state.user = action.payload;
    },
    deleteAuth: () => {
      return { isAuthenticated: false };
    },
  },
});

export const { authSuccess, deleteAuth, updateAccessToken, updateUserSession } = authSlice.actions;
export const selectAuth = (state: AppState): AuthState => state.auth;
export default authSlice.reducer;

export const LogoutAction = () => async (dispatch: AppDispatch) => {
  await logout();
  dispatch(deleteAuth());
};
