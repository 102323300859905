export const isEmpty = (property: any) => {
  return (
    property === null ||
    property === "" ||
    typeof property === "undefined" ||
    (property instanceof Array && !property.length) ||
    (typeof property === "string" && property.trim().length === 0)
  );
};

/** Clean object return not empty attritues */
export const clean = (obj: object) => {
  return Object.entries(obj).reduce((a, [k, v]) => (isEmpty(v) ? a : { ...a, [k]: v }), {});
};

const convertTypeForApi = (value: string) => {
  if (!isEmpty(value) && !isNaN(Number(value))) {
    return +value;
  }
  if ("" + value === "true") return 1;
  if ("" + value === "false") return 0;
  return value;
};

const buildFormData = (formData: any, data: any, parentKey?: string) => {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, convertTypeForApi(value));
  }
};

export const jsonToFormData = (data: any) => {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};
